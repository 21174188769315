import React from "react";

const Subcontractor = () => {
  return (
    <div className="Supplier NewJob">
      <div className="JobTable">
        <table>
          <thead>
            <tr>
              <th>Subcontractor ID</th>
              <th>Subcontractor Name</th>
              <th>Service Provided</th>
              <th>Contract Value</th>

              <th>Start Date</th>

              <th>End Date</th>

              <th>Status</th>

              <th>Contact Information</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
        
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Subcontractor;
