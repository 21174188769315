import React, { useState } from "react";
import SupplierRow from "../components/Supplier/SupplierRow";
import AddSupplier from "../components/Supplier/AddSupplier";

const Supplier = () => {
  const [OpenPopup, setOpenPopup] = useState(false);
  const handleJobPopup = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };
  return (
    <div className="Supplier NewJob">
      <div className="FilterTable">
        <div></div>
        <div className="AddJobs" onClick={handleJobPopup}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.3335 8.66665H3.3335V7.33331H7.3335V3.33331H8.66683V7.33331H12.6668V8.66665H8.66683V12.6666H7.3335V8.66665Z"
              fill="currentcolor"
            />
          </svg>

          <div>Add Supplier</div>
        </div>
      </div>
      {OpenPopup && (
          <div className="AddJobForm">
            <AddSupplier handleClose={handleClose} />
          </div>
        )}
      <div className="JobTable">
        <table>
          <thead>
            <tr>
              <th>Username</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Role</th>
              <th>Department</th>
              <th>Date Created</th>
              <th>Last Login</th>
              <th>Permissions</th>
              <th>Password Reset Required</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>demo</td>
              <td>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenPopup(true);
                  }}
                  title="Edit"
                  className="EditJob"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0,0,256,256"
                    width="15px"
                    height="15px"
                    fill-rule="nonzero"
                  >
                    <g
                      fill="currentcolor"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="1"
                      stroke-linecap="butt"
                      stroke-linejoin="miter"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                    >
                      <g transform="scale(5.12,5.12)">
                        <path d="M43.125,2c-1.24609,0 -2.48828,0.48828 -3.4375,1.4375l-0.8125,0.8125l6.875,6.875c-0.00391,0.00391 0.8125,-0.8125 0.8125,-0.8125c1.90234,-1.90234 1.89844,-4.97656 0,-6.875c-0.95312,-0.94922 -2.19141,-1.4375 -3.4375,-1.4375zM37.34375,6.03125c-0.22656,0.03125 -0.4375,0.14453 -0.59375,0.3125l-32.4375,32.46875c-0.12891,0.11719 -0.22656,0.26953 -0.28125,0.4375l-2,7.5c-0.08984,0.34375 0.01172,0.70703 0.26172,0.95703c0.25,0.25 0.61328,0.35156 0.95703,0.26172l7.5,-2c0.16797,-0.05469 0.32031,-0.15234 0.4375,-0.28125l32.46875,-32.4375c0.39844,-0.38672 0.40234,-1.02344 0.01563,-1.42187c-0.38672,-0.39844 -1.02344,-0.40234 -1.42187,-0.01562l-32.28125,32.28125l-4.0625,-4.0625l32.28125,-32.28125c0.30078,-0.28906 0.39063,-0.73828 0.22266,-1.12109c-0.16797,-0.38281 -0.55469,-0.62109 -0.97266,-0.59766c-0.03125,0 -0.0625,0 -0.09375,0z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Supplier;
