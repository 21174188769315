import Labour from "../components/Jobs/Invoicing/Labour";
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelUpload,
  GetTimesheetData,
  QuotesList,
  ResetSuccessUpdate,
  UpdateKpi,
  VariationKPIList,
} from "../reducers/AllApis";
import toast, { Toaster } from "react-hot-toast";
import { formatMonth } from "../utils/utils";
import numeral from "numeral";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import CircularProgress from "@mui/joy/CircularProgress";
import progressicon from "../assets/images/progressicon.svg";
import labourtarget from "../assets/images/labourtarget.svg";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import Variation from "../components/Jobs/Invoicing/Variation";
import ArrowDown from "../assets/images/Arrow-down.png";
import Materials from "../components/Jobs/Invoicing/Materials";
import Equipment from "../components/Jobs/Invoicing/Equipment";
import Special from "../components/Jobs/Invoicing/Special";
import Subcontractor from "../components/Jobs/Invoicing/Subcontractor";

const Details1 = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const QuoteId = location.state?.data;

  const [JobData, setJobData] = useState([]);
  const [SelectQuote, setSelectQuote] = useState("");
  const {
    QuotesListData,
    SuccessUpdate,
    TimesheetData,
    SelectedTime,
    VariationKPIListData,
  } = useSelector((state) => state.allapi);
  const [IsUploaded, setIsUploaded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [Canceled, isCanceled] = useState(false);

  const IdToken = localStorage.getItem("IdToken");
  const Job_Actual = JobData ? JobData.job_actual : [];

  const config = {
    headers: {
      Authorization: `Bearer ${IdToken}`,
    },
  };

  function ListTableDet() {
    axios
      .get(
        `https://quotech-api.finsoeasy.com/api/report/job/${QuoteId}`,
        config
      )
      .then((res) => {
        setJobData(res.data);
        setSelectQuote(res.data.quote[0]);
        sessionStorage.setItem("JobDetail", JSON.stringify(res.data));
      })
      .catch((err) => {});
  }

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("JobDetail");
    };
  }, []);

  useEffect(() => {
    dispatch(VariationKPIList(QuoteId));
  }, []);

  const userData = {
    type: SelectedTime,
    quote: QuoteId,
  };

  useEffect(() => {
    ListTableDet();
    dispatch(GetTimesheetData(userData));
  }, [SelectedTime]);

  useEffect(() => {
    if (SelectQuote !== "") {
      dispatch(QuotesList(SelectQuote));
    }
  }, [SelectQuote]);

  const TotalEstRev =
    (QuotesListData && QuotesListData.labour_est_revenue
      ? QuotesListData.labour_est_revenue
      : 0) +
    (QuotesListData && QuotesListData.product_est_revenue
      ? QuotesListData.product_est_revenue
      : 0) +
    (QuotesListData && QuotesListData.plant_rental_and_equip_est_revenue
      ? QuotesListData.plant_rental_and_equip_est_revenue
      : 0) +
    (QuotesListData && QuotesListData.sub_contract_est_revenue
      ? QuotesListData.sub_contract_est_revenue
      : 0) +
    (QuotesListData && QuotesListData.other_charges_est_revenue
      ? QuotesListData.other_charges_est_revenue
      : 0);

  const TotalActualMargin =
    (QuotesListData && QuotesListData.labour_actual_margin
      ? QuotesListData.labour_actual_margin
      : 0) +
    (QuotesListData && QuotesListData.product_actual_margin
      ? QuotesListData.product_actual_margin
      : 0) +
    (QuotesListData && QuotesListData.plant_rental_and_equip_actual_margin
      ? QuotesListData.plant_rental_and_equip_actual_margin
      : 0) +
    (QuotesListData && QuotesListData.sub_contract_actual_margin
      ? QuotesListData.sub_contract_actual_margin
      : 0) +
    (QuotesListData && QuotesListData.other_charges_actual_margin
      ? QuotesListData.other_charges_actual_margin
      : 0);

  const TotalEstMargin =
    (QuotesListData && QuotesListData.labour_est_margin
      ? QuotesListData.labour_est_margin
      : 0) +
    (QuotesListData && QuotesListData.product_est_margin
      ? QuotesListData.product_est_margin
      : 0) +
    (QuotesListData && QuotesListData.plant_rental_and_equip_est_budget
      ? QuotesListData.plant_rental_and_equip_est_budget
      : 0) +
    (QuotesListData && QuotesListData.sub_contract_est_margin
      ? QuotesListData.sub_contract_est_margin
      : 0) +
    (QuotesListData && QuotesListData.other_charges_est_margin
      ? QuotesListData.other_charges_est_margin
      : 0);

  const TotalEstBudget =
    (QuotesListData && QuotesListData.labour_est_budget
      ? QuotesListData.labour_est_budget
      : 0) +
    (QuotesListData && QuotesListData.product_est_budget
      ? QuotesListData.product_est_budget
      : 0) +
    (QuotesListData && QuotesListData.plant_rental_and_equip_est_budget
      ? QuotesListData.plant_rental_and_equip_est_budget
      : 0) +
    (QuotesListData && QuotesListData.sub_contract_est_budget
      ? QuotesListData.sub_contract_est_budget
      : 0) +
    (QuotesListData && QuotesListData.other_charges_est_budget
      ? QuotesListData.other_charges_est_budget
      : 0);

  const TotalActualCTC =
    (QuotesListData && QuotesListData.labour_actual_ctc
      ? QuotesListData.labour_actual_ctc
      : 0) +
    (QuotesListData && QuotesListData.product_actual_ctc
      ? QuotesListData.product_actual_ctc
      : 0) +
    (QuotesListData && QuotesListData.plant_rental_and_equip_actual_ctc
      ? QuotesListData.plant_rental_and_equip_actual_ctc
      : 0) +
    (QuotesListData && QuotesListData.sub_contract_actual_ctc
      ? QuotesListData.sub_contract_actual_ctc
      : 0) +
    (QuotesListData && QuotesListData.other_charges_actual_ctc
      ? QuotesListData.other_charges_actual_ctc
      : 0);

  const TotalBudgetOverUnder =
    ((TotalActualCTC - TotalEstBudget) / TotalEstBudget) * 100;

  const contractValueGpPer =
    TotalEstMargin && TotalEstRev ? (TotalEstMargin / TotalEstRev) * 100 : 0;

  const LabourGPPer =
    QuotesListData &&
    QuotesListData.labour_est_margin &&
    QuotesListData.labour_est_revenue
      ? (QuotesListData.labour_est_margin / QuotesListData.labour_est_revenue) *
        100
      : 0;

  const ProductGPPer =
    QuotesListData &&
    QuotesListData.product_est_margin &&
    QuotesListData.product_est_revenue
      ? (QuotesListData.product_est_margin /
          QuotesListData.product_est_revenue) *
        100
      : 0;

  const PPEGPPer =
    QuotesListData &&
    QuotesListData.plant_rental_and_equip_est_margin &&
    QuotesListData.plant_rental_and_equip_est_revenue
      ? (QuotesListData.plant_rental_and_equip_est_margin /
          QuotesListData.plant_rental_and_equip_est_revenue) *
        100
      : 0;

  const SubConGPPer =
    QuotesListData &&
    QuotesListData.sub_contract_est_margin &&
    QuotesListData.sub_contract_est_revenue
      ? (QuotesListData.sub_contract_est_margin /
          QuotesListData.sub_contract_est_revenue) *
        100
      : 0;

  const OtherGPPer =
    QuotesListData &&
    QuotesListData.other_charges_est_margin &&
    QuotesListData.other_charges_est_revenue
      ? (QuotesListData.other_charges_est_margin /
          QuotesListData.other_charges_est_revenue) *
        100
      : 0;

  const handleUploadChange = (e) => {
    isCanceled(false);

    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("job_id", QuoteId);

      setIsLoading(true);
      dispatch(UpdateKpi(formData));
    }
  };

  const handleCancelButtonClick = () => {
    cancelUpload();
    isCanceled(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (SuccessUpdate === true) {
      ListTableDet();
      dispatch(GetTimesheetData(userData));
      setIsLoading(false);
      setIsUploaded(true);
      setTimeout(() => {
        setIsUploaded(false);
        dispatch(ResetSuccessUpdate());
      }, 3000);
    } else if (SuccessUpdate === "Failed" && Canceled === false) {
      setIsLoading(false);
      setIsUploaded(false);

      toast.error("Something is wrong", {
        position: "top-center",
      });
      setTimeout(() => {
        dispatch(ResetSuccessUpdate());
      }, 3000);
    } else if (SuccessUpdate === "Failed" && Canceled === true) {
      setIsLoading(false);
      setIsUploaded(false);
      toast.error("Upload canceled", {
        position: "top-center",
      });
      setTimeout(() => {
        dispatch(ResetSuccessUpdate());
      }, 3000);
    }
  }, [SuccessUpdate]);

  const TimeSheetInvoice = TimesheetData ? TimesheetData : [];

  const Time_Sheet1 =
    TimeSheetInvoice && TimeSheetInvoice.timesheets
      ? TimeSheetInvoice.timesheets
      : [];
  const Invoicing_Data1 =
    TimeSheetInvoice && TimeSheetInvoice.invoices
      ? TimeSheetInvoice.invoices
      : [];
  const Quote_Sheets =
    TimeSheetInvoice && TimeSheetInvoice.quotesheets
      ? TimeSheetInvoice.quotesheets[0]
      : [];

  const VariationKPIJob =
    VariationKPIListData && VariationKPIListData.job
      ? VariationKPIListData.job
      : "";

  const VariationKPIJobData =
    VariationKPIListData && VariationKPIListData.variations
      ? VariationKPIListData.variations
      : "";

  const VariationKPIJoblastupdated =
    VariationKPIListData && VariationKPIListData.last_updated
      ? VariationKPIListData.last_updated
      : "";

  const allPeriods = Array.from(
    new Set([
      ...Time_Sheet1.map((item) => item.period),
      ...Invoicing_Data1.map((item) => item.period),
    ])
  ).sort();

  const allPeriodsQuerter = Array.from(
    new Set([
      ...Time_Sheet1.map((item) => item.quarter),
      ...Invoicing_Data1.map((item) => item.quarter),
    ])
  ).sort();

  function getDefaultObject(fields) {
    const defaultObj = {};
    for (const field of fields) {
      defaultObj[field] = null;
    }
    return defaultObj;
  }

  function fillMissingPeriods(array, allPeriods) {

    const allFields = Array.from(
      new Set(array.flatMap((item) => Object.keys(item)))
    );

    if (SelectedTime === "quarterly") {
      return allPeriodsQuerter.map((period) => {
        const existing = array.find((item) => item.quarter === period);
        if (existing) return existing;
        return {
          period,
          ...getDefaultObject(allFields.filter((field) => field !== "quarter")),
        };
      });
    } else {
      return allPeriods.map((period) => {
        const existing = array.find((item) => item.period === period);
        if (existing) return existing;
        return {
          period,
          ...getDefaultObject(allFields.filter((field) => field !== "period")),
        };
      });
    }
  }

  const Time_Sheet = fillMissingPeriods(Time_Sheet1, allPeriods);
  const Invoicing_Data = fillMissingPeriods(Invoicing_Data1, allPeriods);

  // const LabourInsightsHeader = Time_Sheet.map((x) => (
  //   <th key={x.period}>{formatMonth(x.period)}</th>
  // ));

  const LabourInsightsHeader = Time_Sheet.map((x) => SelectedTime === "quarterly"? x.quarter : x.period);


  const RevenueBookedTotal = Invoicing_Data.reduce(
    (acc, x) => acc + x.total_revenue,
    0
  );

  // const TotalLabourChargeout =
  //   Quote_Sheets?.est_labour_hours_total *
  //   Quote_Sheets?.labour_revenue_per_hour;

  const TotalLabourChargeout = Quote_Sheets?.labour_est_revenue;

  const LabourBudgetCTC =
    Quote_Sheets?.est_labour_hours_total * Quote_Sheets?.labour_ctc_per_hour;

  const PerLabRev = QuotesListData.revenue
    ? (TotalLabourChargeout / QuotesListData.revenue) * 100
    : 0;

  const PPERev = QuotesListData.revenue * 0.1;

  const MatRevPerCO =
    (Quote_Sheets?.product_est_revenue / QuotesListData.revenue) * 100;
  const PPERevPerCO = (PPERev / QuotesListData.revenue) * 100;
  const SubConRevPerCO = (PPERev - 240000 / QuotesListData.revenue) * 100;
  const OtherRevPerCO = 0;

  const LabourRevGrossMargin =
    (Quote_Sheets?.labour_gross_profit /
      Quote_Sheets?.labour_revenue_per_hour) *
    100;

  const LabourRevPer = ((100 - LabourRevGrossMargin) * PerLabRev) / 100;

  const ActualLabourRev = Invoicing_Data.map((x) => (
    <td key={x.total_revenue}>
      {numeral((x.total_revenue * PerLabRev) / 100).format("$0,0")}
    </td>
  ));

  const TotalActualLabourRev = Invoicing_Data.reduce(
    (acc, x) => acc + (x.total_revenue * PerLabRev) / 100,
    0
  );

  const TotalActualLabourCost = Time_Sheet.reduce(
    (acc, x) => acc + -(x.total_hours * Quote_Sheets?.labour_ctc_per_hour),
    0
  );

  // const TotalActualLabourCost = Time_Sheet.reduce(
  //   (acc, x) => acc + (x.total_hours * Quote_Sheets?.labour_ctc_per_hour),
  //   0
  // );

  const InvoicingTotalArr = Invoicing_Data.map((x) => x.total_revenue);

  const TotalInvoicingTotalArr = InvoicingTotalArr.reduce(
    (acc, curr) => acc + curr,
    0
  );

  const ActualLabourRevArr = Invoicing_Data.map(
    (invoice) => (invoice.total_revenue * PerLabRev) / 100
  );

  const ActualLabourCostArr = Time_Sheet.map(
    (timesheet) => -(timesheet.total_hours * Quote_Sheets?.labour_ctc_per_hour)
  );

  // const ActualLabourCostArr = Time_Sheet.map(
  //   (timesheet) => (timesheet.total_hours * Quote_Sheets?.labour_ctc_per_hour)
  // );

  const GrossPAL = ActualLabourRevArr.map((x, i) => {
    return x + ActualLabourCostArr[i];
  });

  const GrossPALArr = ActualLabourRevArr.map((x, i) => {
    const cost = ActualLabourCostArr[i] || 0;
    return x + cost;
  });

  const TotalGrossPAL = TotalActualLabourRev + TotalActualLabourCost;

  const TotalPerMargin = TotalActualLabourRev
    ? (TotalGrossPAL / TotalActualLabourRev) * 100
    : 0;

  const PercentMargin = GrossPAL.map((x, i) => {
    return ActualLabourRevArr[i] ? (x / ActualLabourRevArr[i]) * 100 : 0;
  });

  const ForecastedLabourRevenue = Time_Sheet.map(
    (x) => x.total_hours * Quote_Sheets?.labour_revenue_per_hour
  );

  const TotalForecastedLabourRevenue = ForecastedLabourRevenue.reduce(
    (acc, curr) => acc + curr,
    0
  );

  const LabourRevenueVariance = ActualLabourRevArr.map((x, i) => {
    return x - ForecastedLabourRevenue[i];
  });

  const TotalLabourRevenueVariance = LabourRevenueVariance.reduce(
    (acc, curr) => acc + curr,
    0
  );

  const TotalLabourRevenueVariancePer =
    (TotalLabourRevenueVariance / TotalForecastedLabourRevenue) * 100;

  const LabourRevenueVariancePer = LabourRevenueVariance.map((x, i) =>
    ForecastedLabourRevenue[i] ? (x / ForecastedLabourRevenue[i]) * 100 : 0
  );

  const LabourHoursTarget = ActualLabourRevArr.map(
    (x) => x / Quote_Sheets?.labour_revenue_per_hour
  );

  const TotalLabourHoursTarget = LabourHoursTarget.reduce(
    (acc, curr) => acc + curr,
    0
  );

  const BudgetedLabourCostArr = LabourHoursTarget.map(
    (x) => -(x * Quote_Sheets?.labour_ctc_per_hour)
  );

  const TotalBudgetedLabourCost = BudgetedLabourCostArr.reduce(
    (acc, curr) => acc + curr,
    0
  );

  const EarnedValueArr = ActualLabourCostArr.map(
    (x, i) => x - BudgetedLabourCostArr[i]
  );

  const TotalEarnedValueArr = EarnedValueArr.filter(
    (item) => !isNaN(item)
  ).reduce((acc, curr) => acc + curr, 0);

  const ActualLabourHours = Time_Sheet.map((x) => x.total_hours);

  const TotalActualLabourHours = ActualLabourHours.reduce(
    (acc, curr) => acc + curr,
    0
  );

  const LabourHourLG = LabourHoursTarget.map(
    (x, i) => x - ActualLabourHours[i]
  );

  const TotalLabourChargeoutInvoiceTotal = TotalInvoicingTotalArr
    ? TotalInvoicingTotalArr
    : 0;

  // const PerLabRevInvoiceTotal = QuotesListData.revenue
  // ? (TotalLabourChargeoutInvoiceTotal / QuotesListData.revenue) * 100
  // : 0;

  const PerLabRevInvoiceTotal = JobData.total_revenue
    ? (TotalLabourChargeoutInvoiceTotal / JobData.total_revenue) * 100
    : 0;

  const TotalLabourHourLG = LabourHourLG.reduce((acc, curr) => acc + curr, 0);
  const [value, setValue] = React.useState("2");
  const [activeTab, setActiveTab] = useState("2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  const FullHeightTabs = styled(TabList)({
    height: "41px",
    minHeight: "41px",
    "& .MuiTabs-flexContainer": {
      height: "100%",
      minHeight: "100%",
    },
  });
  const showVariation = () => {
    setValue("")
    setActiveTab("variation");
  };

  // const [isQuotePopupOpen, setQuoteIsPopupOpen] = useState(false);
  // const togglequotePopup = () => {
  //   setQuoteIsPopupOpen(!isQuotePopupOpen);
  // };

  const [isQuotePopupOpen, setQuoteIsPopupOpen] = useState(false);
  const [selectQuoteSheet, setSelectQuoteSheet] = useState([]);
  const popupRef = useRef(null);

  const togglequotePopup = () => {
    setQuoteIsPopupOpen(!isQuotePopupOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setQuoteIsPopupOpen(false);
      }
    };

    if (isQuotePopupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isQuotePopupOpen]);

  return (
    <div>
      <div className="UploadSec">
        <div className="BackButton">
          <Link to="/jobs">
            <p className="font-bold cursor-pointer">&larr; Back to the list</p>
          </Link>
        </div>
        <div className="UploadBar">
          <h4 className="text-xl font-semibold text-black dark:text-white flex UploadFileSec ">
            {isLoading === true && (
              <div className="flex gap-5 items-center" style={{ gap: "10px" }}>
                <CircularProgress size={20} className="CircularProgressBar" />

                <button
                  className=" CancelButton font-bold text-sm cursor-pointer inline-flex items-center justify-centerpy-2  text-center text-meta-5 hover:bg-opacity-90 "
                  onClick={handleCancelButtonClick}
                >
                  Cancel Uploading
                </button>
              </div>
            )}

            {isLoading === false && IsUploaded === false && (
              <div className="UploadFileSecSvg flex gap-2">
                <div style={{ marginBottom: "10px" }}>
                  <div className="ViewButton">Upload Quote</div>
                </div>
              </div>
            )}
            {IsUploaded === true && (
              <div className="UploadFileSecSvg flex gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0,0,256,256"
                  width="30px"
                  height="30px"
                >
                  <g
                    fill="#1814f3"
                    fill-rule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                  >
                    <g transform="scale(5.12,5.12)">
                      <path d="M11,4c-3.89844,0 -7,3.10156 -7,7v28c0,3.89844 3.10156,7 7,7h28c3.89844,0 7,-3.10156 7,-7v-24l-2,2.3125v21.6875c0,2.80078 -2.19922,5 -5,5h-28c-2.80078,0 -5,-2.19922 -5,-5v-28c0,-2.80078 2.19922,-5 5,-5h26.40625l1.59375,-2zM43.25,7.75l-19.34375,22.8125l-8.125,-7.59375l-1.375,1.46875l8.90625,8.28125l0.78125,0.71875l0.65625,-0.78125l20,-23.625z"></path>
                    </g>
                  </g>
                </svg>
                Uploaded
              </div>
            )}
            {isLoading === false && (
              <input
                className="HiddenFile"
                style={{ cursor: "pointer", fontSize: "initial" }}
                type="file"
                onChange={handleUploadChange}
              />
            )}
          </h4>
        </div>
      </div>
      <div className="detailsname flex" style={{ gap: "30px" }}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <div
              className="flex DetailsTabs"
              style={{
                gap: "10px",
                overflowX: "auto",
                margin: "5px 0px",
                padding: "5px 0px",
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <FullHeightTabs
                  value={activeTab}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  style={{ alignItems: "center", overflowX: "auto" }}
                >
                  <Tab label="Summary" value="1" disabled />
                  <Tab
                    label="Labour"
                    // className={`detailskpibtn ${
                    //   activeTab !== "variation" ? "labourselected" : ""
                    // }`}
                    value="2"
                    // onClick={() => setActiveTab("2")}
                  />
                  <Tab label="Materials" value="3" disabled/>
                  <Tab label="Equipment" value="4" disabled/>
                  <Tab label="Subcontractors" value="5" disabled/>
                  <Tab label="Special" value="6" disabled/>
                </FullHeightTabs>
              </Box>
              {/* <div className="detailskpibtn">Variation</div> */}
              <div
                className={`detailskpibtn ${
                  activeTab !== "variation" ? "" : "variationbtn"
                }`}
                onClick={showVariation}
              >
                KPI
              </div>
              <div style={{ marginLeft: "auto" }}>
                <div className="QuotationSheet flex" onClick={togglequotePopup}>
                  <div>Variations</div>
                  <div className="QuotationSheetsvg">
                    <img style={{ width: "20px" }} src={ArrowDown} />
                    {/* <svg
                    width="19"
                    height="13"
                    viewBox="0 0 19 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.634399 0H18.8135V2.05H0.634399V0ZM0.634399 5.125H18.8135V7.175H0.634399V5.125ZM0.634399 10.25H18.8135V12.3H0.634399V10.25Z"
                      fill="currentcolor"
                    />
                  </svg> */}
                  </div>
                </div>
                {isQuotePopupOpen && (
                  <div className="QuotationSheetpopup" ref={popupRef}>
                    {JobData &&
                      JobData.quote.length > 0 &&
                      JobData.quote.map((x, i) => {
                        return (
                          <div
                            className={`options ${
                              SelectQuote.includes(x) ? "Active" : ""
                            }`}
                            onClick={() => {
                              setSelectQuote([x]);
                              setQuoteIsPopupOpen(false);
                            }}
                            key={i}
                          >
                            {x}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
            {activeTab !== "variation" && (
              <>
                <TabPanel value="1"></TabPanel>
                <TabPanel value="2">
                  <Labour
                    PerLabRevInvoiceTotal={PerLabRevInvoiceTotal}
                    TotalLabourChargeoutInvoiceTotal={
                      TotalLabourChargeoutInvoiceTotal
                    }
                    QuotesListData={QuotesListData}
                    InvoicingTotalArr={InvoicingTotalArr}
                    TotalInvoicingTotalArr={TotalInvoicingTotalArr}
                    ActualLabourRevArr={ActualLabourRevArr}
                    LabourInsightsHeader={LabourInsightsHeader}
                    TotalActualLabourRev={TotalActualLabourRev}
                    ActualLabourRev={ActualLabourRev}
                    TotalActualLabourCost={TotalActualLabourCost}
                    ActualLabourCostArr={ActualLabourCostArr}
                    TotalGrossPAL={TotalGrossPAL}
                    GrossPAL={GrossPAL}
                    TotalPerMargin={TotalPerMargin}
                    PercentMargin={PercentMargin}
                    Time_Sheet={Time_Sheet}
                    TotalForecastedLabourRevenue={TotalForecastedLabourRevenue}
                    ForecastedLabourRevenue={ForecastedLabourRevenue}
                    TotalLabourRevenueVariance={TotalLabourRevenueVariance}
                    LabourRevenueVariance={LabourRevenueVariance}
                    TotalLabourRevenueVariancePer={
                      TotalLabourRevenueVariancePer
                    }
                    LabourRevenueVariancePer={LabourRevenueVariancePer}
                    TotalActualLabourHours={TotalActualLabourHours}
                    ActualLabourHours={ActualLabourHours}
                    TotalLabourHoursTarget={TotalLabourHoursTarget}
                    LabourHoursTarget={LabourHoursTarget}
                    TotalLabourHourLG={TotalLabourHourLG}
                    LabourHourLG={LabourHourLG}
                    Quote_Sheets={Quote_Sheets}
                    TotalLabourChargeout={TotalLabourChargeout}
                    PerLabRev={PerLabRev}
                    BudgetedLabourCostArr={BudgetedLabourCostArr}
                    TotalBudgetedLabourCost={TotalBudgetedLabourCost}
                    EarnedValueArr={EarnedValueArr}
                    TotalEarnedValueArr={TotalEarnedValueArr}
                    JobData={JobData}
                  />
                </TabPanel>
                <TabPanel value="3">
                  <Materials
                       Job_Actual={Job_Actual}
                       jobid={QuoteId}
                       quoteid={SelectQuote}
                       VariationKPIJob={VariationKPIJob}
                       VariationKPIJobData={VariationKPIJobData}
                       VariationKPIJoblastupdated={VariationKPIJoblastupdated}
                  />
                </TabPanel>
                <TabPanel value="4">
                  <Equipment/>
                </TabPanel>
                <TabPanel value="5">
                  <Subcontractor/>
                </TabPanel>
                <TabPanel value="6">
                  <Special/>
                </TabPanel>
              </>
            )}
          </TabContext>
        </Box>
      </div>
      {activeTab === "variation" && (
        <div className="Variation">
          <Variation
            Job_Actual={Job_Actual}
            jobid={QuoteId}
            quoteid={SelectQuote}
            VariationKPIJob={VariationKPIJob}
            VariationKPIJobData={VariationKPIJobData}
            VariationKPIJoblastupdated={VariationKPIJoblastupdated}
          />
        </div>
      )}
    </div>
  );
};

export default Details1;
