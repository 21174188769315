import numeral from "numeral";
import { formatMonth, formatMonth1 } from "../../../utils/utils";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectTimeFilter } from "../../../reducers/AllApis";
import { InfoComp } from "./../../../utils/utils";

const MaterialAnalysis = ({
  LabourInsightsHeader = [],
  TotalActualLabourRev = 0,
  ActualLabourRevArr = [],
  TotalActualLabourCost = 0,
  ActualLabourCostArr = [],
  TotalGrossPAL = 0,
  GrossPAL = [],
  TotalPerMargin = 0,
  PercentMargin = [],
  Time_Sheet = [],
  TotalForecastedLabourRevenue = 0,
  ForecastedLabourRevenue = [],
  TotalLabourRevenueVariance = 0,
  LabourRevenueVariance = [],
  TotalLabourRevenueVariancePer = 0,
  LabourRevenueVariancePer = [],
  TotalActualLabourHours = 0,
  ActualLabourHours = [],
  TotalLabourHoursTarget = 0,
  LabourHoursTarget = [],
  TotalLabourHourLG = 0,
  LabourHourLG = [],
  BudgetedLabourCostArr = [],
  TotalBudgetedLabourCost = 0,
  TotalEarnedValueArr = 0,
  EarnedValueArr = [],
  InvoicingTotalArr = [],
  TotalInvoicingTotalArr = 0,
}) => {
  const maxColumns = Math.max(
    LabourInsightsHeader.length,
    ActualLabourRevArr.length,
    ActualLabourCostArr.length,
    GrossPAL.length,
    PercentMargin.length,
    ForecastedLabourRevenue.length,
    LabourRevenueVariance.length,
    LabourRevenueVariancePer.length,
    ActualLabourHours.length,
    LabourHoursTarget.length,
    LabourHourLG.length,
    BudgetedLabourCostArr.length,
    EarnedValueArr.length,
    InvoicingTotalArr.length
  );

  const getClassName = (value, type) => {
    if (type === "percentage") {
      return Number(value) < 40 ? "reddata" : "green";
    }
    if (type === "variance") {
      return Number(value) < 0 ? "reddata" : "green";
    }
    return "";
  };

  function convertToActualValue(value) {
    if (typeof value === "string") {
      const cleanedValue = value.replace(/[^0-9.-]/g, "");
      return isNaN(cleanedValue) || cleanedValue === ""
        ? null
        : parseFloat(cleanedValue);
    }
    return value;
  }

  const renderRow = (
    dataArr = [],
    totalValue = "0",
    formatFn = null,
    isPercentage = false,
    isCurrency = true,
    type = null
  ) => {
    const actualValues = convertToActualValue(totalValue);

    return (
      <div className="DataTable">
        {Array.from({ length: maxColumns }).map((_, i) => (
          <React.Fragment key={i}>
            <div
              className={`Item ${
                dataArr[i] !== undefined &&
                dataArr[i] !== null &&
                !isNaN(Number(dataArr[i]))
                  ? getClassName(Number(dataArr[i]), type)
                  : ""
              }`}
            >
              {dataArr[i] !== undefined &&
              dataArr[i] !== null &&
              !isNaN(Number(dataArr[i]))
                ? formatFn
                  ? `${formatFn(Number(dataArr[i]))}`
                  : isPercentage
                  ? `${Number(dataArr[i]).toFixed(1)}`
                  : isCurrency
                  ? numeral(Number(dataArr[i])).format("0,0")
                  : `${Number(dataArr[i]).toFixed(0)}`
                : isPercentage
                ? "0"
                : isCurrency
                ? "0"
                : `0`}
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  };

  const dispatch = useDispatch();

  const detailspopupRef = useRef(null);
  const inputRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const detailstogglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      detailspopupRef.current &&
      !detailspopupRef.current.contains(event.target)
    ) {
      setIsPopupOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { SelectedTime } = useSelector((state) => state.allapi);
  const handleSelectTime = (data) => {
    dispatch(SelectTimeFilter(data));
  };

  return (
    <div className="LabourAnalysis labourcard ">
     
        <div className="TableNew">
          <div className="TableFieldNames">
            <div className="TableStaticHeading">
              <div className="Item fixedheightheading">
                <div
                  className="detailsfilter"
                  onClick={detailstogglePopup}
                  ref={inputRef}
                  style={{ marginBottom: "-40px" }}
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2212 18V16H14.2212V18H10.2212ZM6.22119 13V11H18.2212V13H6.22119ZM3.22119 8V6H21.2212V8H3.22119Z"
                      fill="currentColor"
                    />
                  </svg>
                  <p>
                    {SelectedTime === "monthly"
                      ? "Monthly"
                      : SelectedTime === "yearly"
                      ? "Yearly"
                      : SelectedTime === "quarterly"
                      ? "Quarterly"
                      : "View by"}
                  </p>

                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5427 8.95001L14.0227 15.47C13.2527 16.24 11.9927 16.24 11.2227 15.47L4.70267 8.95001"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  {isPopupOpen && (
                    <div
                      className="graphpopup"
                      ref={detailspopupRef}
                      style={{
                        marginTop: "200px",
                        marginRight: "10px",
                      }}
                    >
                      <div className="popup-content">
                        <p onClick={(e) => handleSelectTime("monthly")}>
                          Monthly
                        </p>
                        <p onClick={(e) => handleSelectTime("yearly")}>
                          Yearly
                        </p>
                        <p onClick={(e) => handleSelectTime("quarterly")}>
                          Quarterly
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="Item">
              Invoices Raised ($) <InfoComp text="Demo" />
              </div>
              <div className="Item subitem">
              Supplier Bills with Project #
                <InfoComp text="Demo" />
              </div>
              <div className="Item subitem">
              Materials Margin  ($)
                <InfoComp text="Demo" />
              </div>
              <div className="Item subitem">
              % Margin
                <InfoComp text="Demo" />
              </div>
              <div className="Item subitem">
              Material Margin Target ($)
                <InfoComp text="Demo" />
              </div>
              <div className="Item subitem">
              Over Under (%)
                <InfoComp text="Demo" />
              </div>
              <div className="Item divider"></div>
            </div>
          </div>
          <div className="TableFieldData TableStaticField">
            <div className="DataTable Heading fixedheightheading">
              <div className="Item Total" style={{ borderRight: "0" }}>
                <div className="First" style={{ color: "#00000000" }}>
                  hi
                </div>
                <div
                  className="Second"
                  style={{ borderRight: "1px solid var(--item-total)" }}
                >
                  Total
                </div>
              </div>
            </div>
            <div className="DataTable">
              <div className="Item Total">100</div>
            </div>
            <div className="DataTable">
              <div className="Item Total">100</div>
            </div>
            <div className="DataTable">
              <div className="Item Total">100</div>
            </div>
            <div className="DataTable">
              <div className="Item Total">100</div>
            </div>
            <div className="DataTable">
              <div className="Item Total">100</div>
            </div>
            <div className="DataTable">
              <div className="Item Total">100</div>
            </div>
    
            
          </div>
          <div className="TableFieldData TableFieldValues">
            <div className="DataTable Heading fixedheightheading">
              {Array.from({ length: maxColumns }).map((_, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className="Item">
                      {LabourInsightsHeader[i] ? (
                        <>
                          <div className="First">
                            {SelectedTime === "quarterly"
                              ? LabourInsightsHeader[i]
                                  .split(" ")
                                  .slice(0, 2)
                                  .join(" ")
                              : formatMonth1(LabourInsightsHeader[i])}
                          </div>
                          <div className="Second">
                            {SelectedTime === "monthly"
                              ? "Month"
                              : SelectedTime === "yearly"
                              ? "Year"
                              : SelectedTime === "quarterly"
                              ? LabourInsightsHeader[i]
                              : ""}
                            {SelectedTime !== "quarterly" && i + 1}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
      
       
          </div>
        </div>
     
    </div>
  );
};

export default MaterialAnalysis;
