import React, { useEffect, useState } from "react";

import toast, { Toaster } from "react-hot-toast";
import {
  InfoComp,
} from "../../../utils/utils";


const Equipment = () => {
  return (
    <div className="Supplier NewJob">
    <div className="JobTable">
      <table>
        <thead>
          <tr>
           
            <th>ID</th>
            <th>Equipment Name</th>
            <th>Model/Serial Number</th>
            <th>Rental or Owned</th>
            <th>Usage Hours</th>
            <th>Cost</th>
            <th>Location</th>
            <th>Condition</th>
            <th>Last Maintenance Date</th>
  
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
      
          </tr>
        </tbody>
      </table>
    </div>
  </div>
    // <div className="material equipment">
    //   <Toaster />
    //   <div className="LabourAnalysis labourcard ">
    //     <div className="TableNew">
    //       <div className="TableFieldNames">
    //         <div className="TableStaticHeading">
    //           <div className="Item fixedheightheading">
             
    //           </div>
    //           <div className="Item">
    //             Equipment ID <InfoComp text="Demo" />
    //           </div>
    //           <div className="Item subitem">
    //             Equipment Name
    //             <InfoComp text="Demo" />
    //           </div>
    //           <div className="Item subitem">
    //             Model/Serial Number
    //             <InfoComp text="Demo" />
    //           </div>
    //           <div className="Item subitem">
    //             Rental or Owned
    //             <InfoComp text="Demo" />
    //           </div>
    //           <div className="Item subitem">
    //             Usage Hours
    //             <InfoComp text="Demo" />
    //           </div>
    //           <div className="Item subitem">
    //             Cost
    //             <InfoComp text="Demo" />
    //           </div>
    //           <div className="Item subitem">
    //             Location
    //             <InfoComp text="Demo" />
    //           </div>
    //           <div className="Item subitem">
    //             Condition
    //             <InfoComp text="Demo" />
    //           </div>
    //           <div className="Item subitem">
    //             Last Maintenance Date
    //             <InfoComp text="Demo" />
    //           </div>

    //           <div className="Item divider"></div>
    //         </div>
    //       </div>
    //       <div className="TableFieldData TableStaticField">
    //         <div className="DataTable Heading fixedheightheading">
    //           <div className="Item Total" style={{ borderRight: "0" }}>
    //             <div className="First" style={{ color: "#00000000" }}>
    //               hi
    //             </div>
    //             <div
    //               className="Second"
    //               style={{ borderRight: "1px solid var(--item-total)" }}
    //             >
    //               Description
    //             </div>
    //           </div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //       </div>
    //       <div className="TableFieldData TableFieldValues">
    //         <div className="DataTable Heading fixedheightheading">
    //           <div className="Item Total" style={{ borderRight: "0" }}>
    //             <div className="First" style={{ color: "#00000000" }}>
    //               hi
    //             </div>
    //             <div className="Second">Data Source</div>
    //           </div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
    //         <div className="DataTable">
    //           <div className="Item">100</div>
    //         </div>
        
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};
export default Equipment;
