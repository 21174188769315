import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddVar, QuotesList, ResetSuccessUpdate, UpdateVar, VariationKPIList } from "../../reducers/AllApis";
import { Toaster } from "react-hot-toast";

const AddVariation = ({ handleClose, jobid, quoteid, initialData }) => {
  const dispatch = useDispatch();

  const { AddVarSuccess,UpdateVarSuccess } = useSelector((state) => state.allapi);

  const [formdata, setFormData] = useState({
    job_id: jobid,
    quote_number:
      initialData !== null && initialData.quote_number !== ""
        ? initialData.quote_number
        : "",
    revenue:
      initialData !== null && initialData.revenue!== "" ? initialData.revenue : "",
    labour_est_revenue:
      initialData !== null && initialData.labour_est_revenue!== ""
        ? initialData.labour_est_revenue
        : "",
    product_est_revenue:
      initialData !== null && initialData.product_est_revenue!== ""
        ? initialData.product_est_revenue
        : "",
    plant_rental_and_equip_est_revenue:
      initialData !== null && initialData.plant_rental_and_equip_est_revenue!== ""
        ? initialData.plant_rental_and_equip_est_revenue
        : "",
    sub_contract_est_revenue:
      initialData !== null && initialData.sub_contract_est_revenue!== ""
        ? initialData.sub_contract_est_revenue
        : "",
    other_charges_charge_out:
      initialData !== null && initialData.other_charges_charge_out!== ""
        ? initialData.other_charges_charge_out
        : "",
    margin:
      initialData !== null && initialData.margin!== "" ? initialData.margin : "",
      est_labour_hours_total:
      initialData !== null && initialData.est_labour_hours_total !== ""
        ? initialData.est_labour_hours_total
        : "",
    approved:
      initialData !== null && initialData.approved !== "" ? initialData.approved : "",
  });


  

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formdata,
      [name]: value,
    });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formdata).forEach((key) => {
      if (formdata[key] === "") {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (initialData) {
        const changedFields = Object.keys(formdata).reduce((acc, key) => {
          if (key !== "job_id" && formdata[key] !== initialData[key]) {
            acc[key] = formdata[key];
          }
          return acc;
        }, {});

        if (Object.keys(changedFields).length > 0) {
          // changedFields.quote_number = initialData.quote_number;

          const obj = {
            id: initialData.id,
            formdata: changedFields
          }


          

          dispatch(UpdateVar(obj))
        }
      } else {
        dispatch(AddVar(formdata));
      }
    }
  };


  useEffect(() => {

    if (UpdateVarSuccess === true){
      dispatch(VariationKPIList(jobid))
      handleClose()
      dispatch(ResetSuccessUpdate())
    }

  }, [UpdateVarSuccess])


  useEffect(() => {
    if (AddVarSuccess === true) {
      dispatch(VariationKPIList(jobid))
      handleClose()
      dispatch(ResetSuccessUpdate())

    }
  }, [AddVarSuccess]);

  return (
    <div className="AddVariation AddJobsComp DateRangeFilter">

      <div className="Close" onClick={handleClose}>
        <svg
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.9 14L0.5 12.6L6.1 7L0.5 1.4L1.9 0L7.5 5.6L13.1 0L14.5 1.4L8.9 7L14.5 12.6L13.1 14L7.5 8.4L1.9 14Z"
            fill="currentcolor"
          />
        </svg>
      </div>

      <div className="InputDiv">
        <div className="Head">Quote Number</div>
        <div className="Input">
          <input
            disabled={initialData ? true : false}
            type="text"
            name="quote_number"
            value={formdata.quote_number}
            onChange={handleChange}
            style={{ borderColor: errors.quote_number ? "#ff0000" : "" }}
          />
          {errors.quote_number && (
            <div className="ErrNoti">{errors.quote_number}</div>
          )}
        </div>
      </div>

      <div className="InputDiv">
        <div className="Head">Quote Value</div>
        <div className="Input">
          <input
            type="number"
            name="revenue"
            value={formdata.revenue}
            onChange={handleChange}
            style={{ borderColor: errors.revenue ? "#ff0000" : "" }}
          />
          {errors.revenue && <div className="ErrNoti">{errors.revenue}</div>}
        </div>
      </div>

      <div className="InputDiv">
        <div className="Head">Labour</div>
        <div className="Input">
          <input
            type="number"
            name="labour_est_revenue"
            value={formdata.labour_est_revenue}
            onChange={handleChange}
            style={{ borderColor: errors.labour_est_revenue ? "#ff0000" : "" }}
          />
          {errors.labour_est_revenue && (
            <div className="ErrNoti">{errors.labour_est_revenue}</div>
          )}
        </div>
      </div>

      <div className="InputDiv">
        <div className="Head">Product</div>
        <div className="Input">
          <input
            type="number"
            name="product_est_revenue"
            value={formdata.product_est_revenue}
            onChange={handleChange}
            style={{ borderColor: errors.product_est_revenue ? "#ff0000" : "" }}
          />
          {errors.product_est_revenue && (
            <div className="ErrNoti">{errors.product_est_revenue}</div>
          )}
        </div>
      </div>

      <div className="InputDiv">
        <div className="Head">Plant Rental & Equip</div>
        <div className="Input">
          <input
            type="number"
            name="plant_rental_and_equip_est_revenue"
            value={formdata.plant_rental_and_equip_est_revenue}
            onChange={handleChange}
            style={{
              borderColor: errors.plant_rental_and_equip_est_revenue
                ? "#ff0000"
                : "",
            }}
          />
          {errors.plant_rental_and_equip_est_revenue && (
            <div className="ErrNoti">
              {errors.plant_rental_and_equip_est_revenue}
            </div>
          )}
        </div>
      </div>

      <div className="InputDiv">
        <div className="Head">Contract Services</div>
        <div className="Input">
          <input
            type="number"
            name="sub_contract_est_revenue"
            value={formdata.sub_contract_est_revenue}
            onChange={handleChange}
            style={{
              borderColor: errors.sub_contract_est_revenue ? "#ff0000" : "",
            }}
          />
          {errors.sub_contract_est_revenue && (
            <div className="ErrNoti">{errors.sub_contract_est_revenue}</div>
          )}
        </div>
      </div>

      <div className="InputDiv">
        <div className="Head">Other Charges</div>
        <div className="Input">
          <input
            type="number"
            name="other_charges_charge_out"
            value={formdata.other_charges_charge_out}
            onChange={handleChange}
            style={{
              borderColor: errors.other_charges_charge_out ? "#ff0000" : "",
            }}
          />
          {errors.other_charges_charge_out && (
            <div className="ErrNoti">{errors.other_charges_charge_out}</div>
          )}
        </div>
      </div>

      <div className="InputDiv">
        <div className="Head">Gross Margin</div>
        <div className="Input">
          <input
            type="number"
            name="margin"
            value={formdata.margin}
            onChange={handleChange}
            style={{ borderColor: errors.margin ? "#ff0000" : "" }}
          />
          {errors.margin && <div className="ErrNoti">{errors.margin}</div>}
        </div>
      </div>

      <div className="InputDiv">
        <div className="Head">Budgeted Hours</div>
        <div className="Input">
          <input
            type="number"
            name="est_labour_hours_total"
            value={formdata.est_labour_hours_total}
            onChange={handleChange}
            style={{ borderColor: errors.est_labour_hours_total ? "#ff0000" : "" }}
          />
          {errors.est_labour_hours_total && <div className="ErrNoti">{errors.est_labour_hours_total}</div>}
        </div>
      </div>

      <div className="InputDiv">
        <div className="Head">Approval</div>
        <div className="Input">
          <select
            name="approved"
            value={formdata.approved}
            onChange={handleChange}
            style={{ borderColor: errors.approved ? "#ff0000" : "" }}
          >
            <option value=""></option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          {errors.approved && <div className="ErrNoti">{errors.approved}</div>}
        </div>
      </div>
      <div className="CreateJobButton">
        <button onClick={handleSubmit}>
          {
            initialData ? "Update" : "Create"
          }
          </button>
      </div>
    </div>
  );
};

export default AddVariation;
