import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ApproveVariationKPI,
  ResetSuccessUpdate,
  VariationKPIList,
} from "../../../reducers/AllApis";
import toast, { Toaster } from "react-hot-toast";
import {
  DateForm,
  TotalLabCOCircular,
  TotalLabCOProgress,
} from "./../../../utils/utils";
import AddVariation from "../AddVariation";
import MaterialAnalysis from "./MaterialAnalysis";
import progressicon from "../../../assets/images/progressicon.svg";

const Materials = ({
  Job_Actual,
  VariationKPIJoblastupdated,
  VariationKPIJob,
  VariationKPIJobData,
  quoteid,
  jobid,
}) => {
  return (
    <div className="material">
      <Toaster />
      <div className="KPISummarytext">Material stats</div>

      <div className="flex justify-between " style={{paddingTop: "30px"}}>
        <div className="labourcard StatCard MaterialStat" style={{ width: "100%" }}>
          <div
            className="Sec flex chargeoutspace StatCard"
            style={{
              marginBottom: "20px",
              gap: "20px",
              alignItems: "center",
              width: "270px",
            }}
          >
            <div className="LabourStat">
              <div>{TotalLabCOCircular("20")}</div>
              <div
                className="flex"
                style={{
                  justifyContent: "center",
                  marginTop: "-41px",
                  marginBottom: "20px",
                  marginLeft: "-6px",
                }}
              >
                <div className="chargeoutcircle">
                  <img src={progressicon} />
                </div>
              </div>
              <div
                className="subtitle flex"
                style={{ justifyContent: "center" }}
              >
                20%
              </div>
            </div>
            <div>
              <div
                className="flex LabourStat"
                style={{ justifyContent: "space-between" }}
              >
                <div className="subtitle">$100</div>
                <div className="subtitle">$100</div>
              </div>
              <div className="labourStatBar">{TotalLabCOProgress(20)}</div>

              <div className="labourbar">Total Labour Chargeout $</div>
            </div>
          </div>
          <div className="Sec">
            <div style={{ gap: "33px" }}>
              <div style={{ gap: "10px", marginBottom: "10px" }}>
                <div className="cardTitle">Total Revenue</div>
                <div className="cardcontent">$100</div>
              </div>
            </div>
          </div>
          <div className="Sec">
            <div style={{ gap: "33px" }}>
              <div style={{ gap: "10px", marginBottom: "10px" }}>
                <div className="cardTitle">Chargeout Cost Bills</div>
                <div className="cardcontent">$100</div>
              </div>
            </div>
          </div>
          <div className="Sec">
            <div style={{ gap: "33px" }}>
              <div style={{ gap: "10px", marginBottom: "10px" }}>
                <div className="cardTitle">Total Chargeout Products</div>
                <div className="cardcontent">$100</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MaterialAnalysis />
    </div>
  );
};
export default Materials;
