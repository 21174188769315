import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ActiveJobPopup,
  ActivePopup,
  AddJob,
  AddTags,
  EmployeesListManager,
  InactiveJobPopup,
  ListExcel,
  ListExcelSearch,
  ResetManager,
  ResetSuccessUpdate,
  Tags,
  UpdateJob,
  UpdateJobtype,
} from "../reducers/AllApis";
import { CompBar, formatMonth, StatFormat } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import Filter from "./../components/Jobs/Filters";
import DateRangeFilter from "../components/Jobs/DateRangeFilter";
import AddJobs from "../components/Jobs/AddJobs";
import AddJobsNew from "../components/Jobs/AddJobsNew";
import { Toaster } from "react-hot-toast";
import InputFieldSearch from "../components/Jobs/SearchInput";

const JobNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(
    Number(sessionStorage.getItem("currentPage")) || 1
  );
  const [searchTerm, setSearchTerm] = useState(
    sessionStorage.getItem("searchTerm") || ""
  );
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    status: sessionStorage.getItem("status") || "",
    dateRange: sessionStorage.getItem("dateRange") || "",
    tags: JSON.parse(sessionStorage.getItem("tags")) || "",
    projectManager: sessionStorage.getItem("projectManager") || "",
    start_date: sessionStorage.getItem("Custom start date") || "",
    end_date: sessionStorage.getItem("Custom end date") || "",
  });

  const hasEmptyValue = Object.values(filters).some((value) => value !== "");

  const {
    Listdata,
    ListCount,
    ListSearchCount,
    ListSearchdata,
    TagsData,
    ProjectManagersData,
    loading,
    OpenPopup,
    AddTagsSuccess,
    OpenJobPopup,
    EmployeesListManagerData,
    UpdateJobSuccess,
  } = useSelector((state) => state.allapi);
  const JobPopupRef = useRef();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(ListExcelSearch({ search: debouncedSearchTerm }));
      sessionStorage.setItem("searchTerm", debouncedSearchTerm);
      sessionStorage.removeItem("currentPage");
      setCurrentPage(1);
    } else if (
      filters.status === "" &&
      filters.projectManager === "" &&
      filters.tags === "" &&
      filters.dateRange === "" &&
      filters.start_date === "" &&
      filters.end_date === ""
    ) {
      sessionStorage.removeItem("searchTerm");
      dispatch(ListExcel({ page: currentPage }));
    }
  }, [debouncedSearchTerm, currentPage, dispatch, filters, UpdateJobSuccess]);

  useEffect(() => {
    if (TagsData && ProjectManagersData) {
      setIsDataLoaded(true);
    }
  }, [TagsData, ProjectManagersData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    sessionStorage.setItem("currentPage", page);
    if (!debouncedSearchTerm) {
      dispatch(ListExcel({ page }));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNav = (data) => {
    navigate(`/jobs/${data.job_id}`, {
      state: { data: data.job_id },
    });
  };

  const ListDataMain = debouncedSearchTerm ? ListSearchdata : Listdata;
  const ListCountMain = debouncedSearchTerm ? ListSearchCount : ListCount || 0;

  const totalPages = Math.ceil(ListCountMain / 50);
  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [name]: value };

      if (name === "tags") {
        sessionStorage.setItem(name, JSON.stringify(value));
      } else {
        sessionStorage.setItem(name, value);
      }

      return newFilters;
    });
  };

  useEffect(() => {
    const params = new URLSearchParams();

    filters &&
      filters.tags &&
      filters.tags.forEach((tag) => {
        params.append("tags", tag);
      });

    const data = {
      project_manager_name: filters.projectManager,
      status: filters.status,
      filter_type: filters.dateRange,
      custom_start_date: filters.start_date,
      custom_end_date: filters.end_date,
    };

    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        params.append(key, value);
      }
    });
    if (
      params.toString() &&
      ((!filters.start_date && !filters.end_date) ||
        (filters.start_date && filters.end_date))
    ) {
      sessionStorage.removeItem("currentPage");
      dispatch(ListExcel(params));
    }
  }, [
    filters.status,
    filters.dateRange,
    filters.tags,
    filters.projectManager,
    filters.start_date,
    filters.end_date,
    UpdateJobSuccess,
  ]);

  const handleDatePopup = () => {
    dispatch(ActivePopup());
  };

  const handleSelectData = (data) => {
    sessionStorage.setItem("dateRange", data);

    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, dateRange: data };
      return newFilters;
    });
  };

  const handleClearFilter = () => {
    sessionStorage.removeItem("status");
    sessionStorage.removeItem("dateRange");
    sessionStorage.removeItem("tags");
    sessionStorage.removeItem("projectManager");
    sessionStorage.removeItem("Custom end date");
    sessionStorage.removeItem("Custom start date");

    setFilters({
      status: "",
      dateRange: "",
      tags: "",
      projectManager: "",
      start_date: "",
      end_date: "",
    });
    setSearchTerm("");
    setDebouncedSearchTerm("");

    sessionStorage.removeItem("currentPage");

    dispatch(ListExcel({ page: currentPage }));
    // dispatch(ListExcelSearch({ search: searchTerm, page: currentPage }));
    // alert("hi")
  };

  const handleAddTags = (data) => {
    const obj = {
      tag: data,
    };
    dispatch(AddTags(obj));
  };

  useEffect(() => {
    if (AddTagsSuccess === true) {
      dispatch(Tags(""));
      dispatch(ResetSuccessUpdate());
    }
  }, [AddTagsSuccess]);

  const handleClearFil = () => {
    sessionStorage.removeItem("dateRange");
    sessionStorage.removeItem("Custom start date");
    sessionStorage.removeItem("Custom end date");
  };

  const handleSelectDataCustom = (data, name) => {
    sessionStorage.setItem(name, data);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name === "Custom start date" ? "start_date" : "end_date"]: data,
    }));
  };

  const handleJobPopup = () => {
    dispatch(ActiveJobPopup());
  };

  const JobDataPopupRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        JobDataPopupRef.current &&
        !JobDataPopupRef.current.contains(event.target)
      ) {
        dispatch(InactiveJobPopup());
        setEditFields(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const [EditFields, setEditFields] = useState(null);

  const handleEditJob = (data) => {
    setEditFields(data);
    dispatch(ActiveJobPopup());
  };

  const [ShowManager, setShowManager] = useState(false);
  const [singleManager, setSingleManager] = useState("");
  const managerRef = useRef(null);

  const handleManagerSelect = (e) => {
    if (e.target.value.trim() !== "") {
      const debounce = setTimeout(() => {
        dispatch(EmployeesListManager(e.target.value));
      }, 2000);
      return () => clearTimeout(debounce);
    }
  };

  const handleManagerSelection = (data, jobId) => {
    if (data.project_manager) {
      const obj = {
        id: jobId,
        formdata: data,
      };

      setSingleManager(data.project_manager);
      setShowManager(false);

      dispatch(UpdateJob(obj));
    } else {
      console.error("Invalid project_manager data:", data);
    }
  };

  useEffect(() => {
    if (UpdateJobSuccess === true) {
      setShowManager(false);

      console.log("Success", ShowManager);
      
      dispatch(InactiveJobPopup());
      setEditFields(null);
      dispatch(ResetSuccessUpdate());
    }
  }, [UpdateJobSuccess]);

  const handleClickOutsideManager = (event) => {
    if (
      managerRef.current &&
      !managerRef.current.contains(event.target) &&
      event.target.tagName !== "INPUT"
    ) {
      setShowManager(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideManager);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideManager);
    };
  }, []);

  return (
    <div className="NewJob">
      <Toaster />
      <div className="FilterTable">
        <div className="FilterItemDiv">
          <Filter
            name="Status"
            options={["TBC", "WIP*", "WIP", "DONE"]}
            value={filters.status}
            onChange={(value) => handleFilterChange("status", value)}
          />
          <Filter
            name="Date Range"
            options={["Custom"]}
            value={filters.dateRange}
            isDateFilter={true}
            handleSelectData={handleSelectData}
            onChange={(value) => handleFilterChange("dateRange", value)}
            handleOpenDateRange={handleDatePopup}
          />
          <Filter
            name="Tag Selector"
            options={TagsData}
            value={filters.tags}
            onChange={(value) => handleFilterChange("tags", value)}
            isTags={true}
            handleAddTags={handleAddTags}
          />
          <Filter
            name="Project Manager"
            options={ProjectManagersData}
            value={filters.projectManager}
            onChange={(value) => handleFilterChange("projectManager", value)}
            classname="ProjectManager"
          />
          {filters.dateRange ||
          filters.status ||
          filters.projectManager ||
          filters.end_date ||
          filters.start_date ||
          filters.tags ? (
            <div className="Filters ClearFilter" onClick={handleClearFilter}>
              Clear <span style={{ marginLeft: "10px" }}>&#10005;</span>
            </div>
          ) : (
            ""
          )}
          <input
            className="SearchJobs"
            type="text"
            placeholder="Search jobs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* <div className="AddJobs" onClick={handleJobPopup}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.3335 8.66665H3.3335V7.33331H7.3335V3.33331H8.66683V7.33331H12.6668V8.66665H8.66683V12.6666H7.3335V8.66665Z"
              fill="#0035F0"
            />
          </svg>

          <div>Create Job</div>
        </div> */}

        {OpenJobPopup && (
          <div className="AddJobForm">
            <AddJobs ref={JobDataPopupRef} initialData={EditFields} />
            {/* <AddJobsNew ref={JobDataPopupRef}/> */}
          </div>
        )}
        {OpenPopup && (
          <div className="AddJobForm">
            <DateRangeFilter
              Data={filters.dateRange}
              ref={JobPopupRef}
              handleSelectData={handleSelectData}
              handleSelectDataCustom={handleSelectDataCustom}
              handleClearFil={handleClearFil}
              Start_Date={filters.start_date}
              End_Date={filters.end_date}
            />
          </div>
        )}
      </div>

      <div className="JobTable">
        <table ref={managerRef}>
          <thead>
            <tr>
              <th>Project manager</th>
              <th>Job no.</th>
              <th>Start</th>
              <th>End</th>
              <th>Status</th>
              <th>Revenue</th>
              <th>WIP</th>
              <th className="SiteName">Client Name</th>
              <th className="SiteName">Site Name</th>
              <th>% Comp</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {ListDataMain.length > 0 ? (
              ListDataMain.map((x, i) => (
                <tr key={i} onClick={() => handleNav(x)}>
                  <td
                    className="SelectManager"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowManager(x.job_id);
                    }}
                  >
                    {ShowManager === x.job_id ? (
                      <InputFieldSearch
                        value={singleManager}
                        onChangeHandler={handleManagerSelect}
                        searchData={EmployeesListManagerData}
                        placeholder="Enter name.."
                        setFormData={(updater) => {
                          const updatedData = updater({});
                          handleManagerSelection(updatedData, x.job_id);
                        }}
                        formKey="project_manager"
                      />
                    ) : x.project_manager ? (
                      x.project_manager
                    ) : (
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.875 14.625H23.625V18H20.25V20.25H23.625V23.625H25.875V20.25H29.25V18H25.875V14.625ZM9 14.625C9 17.19 10.935 19.125 13.5 19.125C16.065 19.125 18 17.19 18 14.625C18 12.06 16.065 10.125 13.5 10.125C10.935 10.125 9 12.06 9 14.625ZM15.75 14.625C15.75 15.9503 14.8252 16.875 13.5 16.875C12.1747 16.875 11.25 15.9503 11.25 14.625C11.25 13.2998 12.1747 12.375 13.5 12.375C14.8252 12.375 15.75 13.2998 15.75 14.625ZM9 25.875C9 24.0142 10.5143 22.5 12.375 22.5H14.625C16.4858 22.5 18 24.0142 18 25.875V27H20.25V25.875C20.25 22.7734 17.7266 20.25 14.625 20.25H12.375C9.27338 20.25 6.75 22.7734 6.75 25.875V27H9V25.875Z"
                          fill="currentColor"
                        />
                        <circle
                          cx="18"
                          cy="18"
                          r="17.5"
                          stroke="currentColor"
                          strokeLinecap="square"
                          strokeDasharray="1 5"
                        />
                      </svg>
                    )}
                  </td>

                  <td>J{x.job_id || "-"}</td>
                  <td>{x.start_date ? formatMonth(x.start_date) : "-"}</td>
                  <td>{x.end_date ? formatMonth(x.end_date) : "-"}</td>
                  <td>
                    {x.status ? (
                      <StatFormat stat={x.status.toUpperCase()} />
                    ) : (
                      "-"
                    )}
                  </td>

                  <td>{x.revenue ? numeral(x.revenue).format("$0,0") : "-"}</td>
                  <td>{x.wip ? numeral(x.wip).format("$0,0") : "0"}</td>
                  <td>{x.client ? x.client : "-"}</td>

                  <td>{x.site || "-"}</td>
                  <td>
                    {x.percent_complete ? (
                      <CompBar data={x.percent_complete} />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditJob(x);
                      }}
                      title="Edit"
                      className="EditJob"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0,0,256,256"
                        width="15px"
                        height="15px"
                        fill-rule="nonzero"
                      >
                        <g
                          fill="currentcolor"
                          fill-rule="nonzero"
                          stroke="none"
                          stroke-width="1"
                          stroke-linecap="butt"
                          stroke-linejoin="miter"
                          stroke-miterlimit="10"
                          stroke-dasharray=""
                          stroke-dashoffset="0"
                          font-family="none"
                          font-weight="none"
                          font-size="none"
                          text-anchor="none"
                        >
                          <g transform="scale(5.12,5.12)">
                            <path d="M43.125,2c-1.24609,0 -2.48828,0.48828 -3.4375,1.4375l-0.8125,0.8125l6.875,6.875c-0.00391,0.00391 0.8125,-0.8125 0.8125,-0.8125c1.90234,-1.90234 1.89844,-4.97656 0,-6.875c-0.95312,-0.94922 -2.19141,-1.4375 -3.4375,-1.4375zM37.34375,6.03125c-0.22656,0.03125 -0.4375,0.14453 -0.59375,0.3125l-32.4375,32.46875c-0.12891,0.11719 -0.22656,0.26953 -0.28125,0.4375l-2,7.5c-0.08984,0.34375 0.01172,0.70703 0.26172,0.95703c0.25,0.25 0.61328,0.35156 0.95703,0.26172l7.5,-2c0.16797,-0.05469 0.32031,-0.15234 0.4375,-0.28125l32.46875,-32.4375c0.39844,-0.38672 0.40234,-1.02344 0.01563,-1.42187c-0.38672,-0.39844 -1.02344,-0.40234 -1.42187,-0.01562l-32.28125,32.28125l-4.0625,-4.0625l32.28125,-32.28125c0.30078,-0.28906 0.39063,-0.73828 0.22266,-1.12109c-0.16797,-0.38281 -0.55469,-0.62109 -0.97266,-0.59766c-0.03125,0 -0.0625,0 -0.09375,0z"></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <div className="ItemHead">No Data</div>
            )}
          </tbody>
        </table>
      </div>
      {!hasEmptyValue && !searchTerm && (
        <div className="JobPagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          {[...Array(totalPages)].map((_, index) => {
            const page = index + 1;
            if (
              page === 1 ||
              page === totalPages ||
              page === currentPage ||
              page === currentPage - 1 ||
              page === currentPage + 1
            ) {
              return (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  style={{
                    margin: "0 5px",
                    backgroundColor: currentPage === page ? "blue" : "white",
                    color: currentPage === page ? "white" : "black",
                    border: "1px solid #888888",
                    padding: "5px 10px",
                  }}
                >
                  {page}
                </button>
              );
            }
            if (
              (page === currentPage - 2 && currentPage > 3) ||
              (page === currentPage + 2 && currentPage < totalPages - 2)
            ) {
              return (
                <span key={page} style={{ margin: "0 5px" }}>
                  ...
                </span>
              );
            }
            return null;
          })}

          {/* {[...Array(totalPages)].map((_, index) => {
            const page = index + 1;
            return (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                style={{
                  margin: "0 5px",
                  backgroundColor: currentPage === page ? "blue" : "white",
                  color: currentPage === page ? "white" : "black",
                  border: "1px solid #888888",
                  padding: "5px 10px",
                }}
              >
                {page}
              </button>
            );
          })} */}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default JobNew;
