import React, { useState, useEffect, useRef } from "react";

const Filter = ({
  name,
  options = [],
  value,
  onChange,
  isDateFilter = false,
  handleOpenDateRange,
  classname = "",
  isTags = false,
  handleAddTags,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState(isTags === true && value ? value : []);
  const popupRef = useRef(null);

  useEffect(() => {
    if (isTags && value && Array.isArray(value)) {
      setSelectedTags(value);
    }else if (isTags && (!value || value.length === 0)) {
      setSelectedTags([]); 
    }
  }, [value, isTags]);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleSelectTag = (option) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(option.tag)
        ? prevTags.filter((tag) => tag !== option.tag)
        : [...prevTags, option.tag]
    );
  };

  const handleGoClick = () => {
    if (isTags) {
      onChange([...selectedTags]);
      setIsOpen(false);
    }
  };
  const colors = ["#F0E9FF", "#60EF88", "#B0A781", "#F9E0E0"];
  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddTags(event.target.value);
    }
  };



  const SelectedTagsData = selectedTags.length >0 ? selectedTags.join(", ") : ""
  const displayText = SelectedTagsData.length > 10 ? SelectedTagsData.slice(0, 10) + "..." : SelectedTagsData;


  
  const capitalizeWords = (str) => {
    return str
      .split(" ") 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };



  return (
    <div className={`Filters ${classname}`} ref={popupRef}>
      <div
        className="Item"
        onClick={isDateFilter ? handleOpenDateRange : togglePopup}
      >
        <div className="items">
          <div className="Name">{name} :</div>
          <div className="FilterItems">
            {isTags
              ? selectedTags.length > 0
                ? displayText
                : "All"
              : value.length > 10 ? capitalizeWords(value).replace("_", " ").slice(0, 10) + "..." : capitalizeWords(value).replace("_", " ") || "All"}
          </div>
        </div>
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.76117 0.5L5 3.51432L1.23883 0.5L0 1.49284L5 5.5L10 1.49284L8.76117 0.5Z"
            fill="#0035F0"
          />
        </svg>
      </div>

      {isOpen && (
        <div>
          {isTags ? (
            <div className="FilterOptions">
              <div className="TagOptions">
                {options.length>0 ? options.map((option, i) => (
                  <div
                    className={`Options ${
                      selectedTags.includes(option.tag) ? "Active" : ""
                    }`}
                    key={option.id}
                    onClick={() => handleSelectTag(option)}
                    style={{
                      backgroundColor: colors[i % colors.length],
                    }}
                  >
                    <div
                      className={`checkbox ${
                        selectedTags.includes(option.tag) && "Active"
                      }`}
                    ></div>
                    <div className="Option">{option.tag}</div>
                  </div>
                )) : <div className="Options">No tags</div>}
              </div>
              {
                options.length>0 &&    <div onClick={handleGoClick} className="GoButton">
                Filter &rarr;
              </div>
              }
           
              <div className="Options">
                <input
                  type="text"
                  placeholder="Create new..."
                  onKeyPress={handleInputKeyPress}
                />
              </div>
            </div>
          ) : (
            <div className="FilterOptions">
              {options.length> 0 ? options.map((option) => (
                <div
                  className={`Options ${value === option ? "Active" : ""}`}
                  key={option}
                  onClick={() => {
                    onChange(option);
                    setIsOpen(false);
                  }}
                >
                  {option}
                </div>
              )) : <div className="Options">No project managers</div>}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Filter;
