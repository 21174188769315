import React, { useEffect, useState } from "react";

import toast, { Toaster } from "react-hot-toast";
import {
  InfoComp,
  TotalLabCOCircular,
  TotalLabCOProgress,
} from "../../../utils/utils";
import MaterialAnalysis from "./MaterialAnalysis";
import progressicon from "../../../assets/images/progressicon.svg";

const Special = () => {
  return (
    <div className="Supplier NewJob">
    <div className="JobTable">
      <table>
        <thead>
          <tr>
           
            <th>Requirement ID</th>
          
            <th>Description of Requirement</th>
      
            <th>Description</th>
            <th>Responsible Party</th>
            <th>Deadline</th>
            <th>Status</th>
            <th>Notes</th>
  
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
            <td>demo</td>
         
      
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  );
};
export default Special;
