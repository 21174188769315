import React, { useState, forwardRef } from "react";



const AddAssets = forwardRef(({ initialData, handleClose }, ref) => {



  const handleSubmit = () => {

  };

  return (
    <div className="AddJobsComp JobComp" ref={ref}>
      <div className="Close">
        <svg
          onClick={handleClose}
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.9 14L0.5 12.6L6.1 7L0.5 1.4L1.9 0L7.5 5.6L13.1 0L14.5 1.4L8.9 7L14.5 12.6L13.1 14L7.5 8.4L1.9 14Z"
            fill="currentcolor"
          />
        </svg>
      </div>
      <div>
        <div className="InputDiv">
          <div className="Head">Name</div>
          <div className="Input">
            <input
            
            />
          </div>
        </div>
        <div className="InputDiv">
          <div className="Head">Description</div>
          <div className="Input">
            <input
           
            />
          </div>
        </div>
      

        <div className="InputDiv">
          <div className="Head">Category</div>
          <div className="Input">
            <input
            
            />
          </div>
        </div>
        <div className="InputDiv">
          <div className="Head">Purchase Date</div>
          <div className="Input">
            <input
          
            />
          </div>
        </div>
        <div className="InputDiv">
          <div className="Head">Cost</div>
          <div className="Input">
            <input
        
            />
          </div>
        </div>

        <div className="InputDiv">
          <div className="Head">Condition</div>
          <div className="Input">
            <input
            
            />
          </div>
        </div>

        <div className="InputDiv">
          <div className="Head">Location</div>
          <div className="Input">
            <input
              
            />
          </div>
        </div>

        <div className="InputDiv">
          <div className="Head">Serial/Model Number</div>
          <div className="Input">
            <input
           
            />
          </div>
        </div>

        <div className="InputDiv">
          <div className="Head">Warranty Expiry Date</div>
          <div className="Input">
            <input
          
            />
          </div>
        </div>

        <div className="InputDiv">
          <div className="Head">Responsible Person</div>
          <div className="Input">
            <input
            
            />
          </div>
        </div>

        <div className="InputDiv">
          <div className="Head">Maintenance Schedule</div>
          <div className="Input">
            <input
            
            />
          </div>
        </div>
        <div className="InputDiv">
          <div className="Head">Last Maintenance Date</div>
          <div className="Input">
            <input
            
            />
          </div>
        </div>
        <div className="InputDiv">
          <div className="Head">Depreciation Method</div>
          <div className="Input">
            <input
            
            />
          </div>
        </div>
        <div className="InputDiv">
          <div className="Head">Depreciated Value</div>
          <div className="Input">
            <input
            
            />
          </div>
        </div>
        <div className="InputDiv">
          <div className="Head">Disposal Date</div>
          <div className="Input">
            <input
            
            />
          </div>
        </div>
        <div className="InputDiv">
          <div className="Head">Notes</div>
          <div className="Input">
            <input
            
            />
          </div>
        </div>

      
        <div className="CreateJob CreateJobButton">
          <button onClick={handleSubmit}>
            {initialData ? "Update Asset" : "Create Asset"}
          </button>
        </div>
      </div>
    </div>
  );
});

export default AddAssets;
